<template>
  <div class="forgot-password-view">
    <div class="forgot-password-wrap">
      <div class="forgot-password-box">
        <lay-form :model="forgotPasswordModel" ref="forgotPasswordForm" :rules="rules" name="forgotPasswordForm">
          <div class="form-title">Forgot Password</div>
          <lay-form-item class="form-item" prop="email" v-if="!verificationPassed">
            <label class="form-label">Email</label>
            <lay-input type="text" v-model="forgotPasswordModel.email" class="form-input">
              <template #prefix>
                <font-awesome-icon icon="fa-regular fa-envelope" style="font-weight: 900; font-size: 25px; color: #090970"/>
              </template>
            </lay-input>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="!verificationPassed">
            <lay-button class="form-btn" @click="sendCode" :disabled="countdown > 0">
              {{ countdown > 0 ? `Resend Code (${countdown})` : 'Send Code' }}
            </lay-button>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="showVerification && !verificationPassed">
            <label class="form-label">Verification Code</label>
            <lay-input type="text" v-model="forgotPasswordModel.verificationCode" class="form-input">
              <template #prefix>
                <font-awesome-icon icon="fa-solid fa-lock" style="font-weight: 900; font-size: 25px; color: #090970"/>
              </template>
            </lay-input>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="showVerification && !verificationPassed">
            <lay-button class="form-btn" @click="verifyCode">Verify Code</lay-button>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="verificationPassed">
            <label class="form-label">New Password</label>
            <lay-input type="password" v-model="forgotPasswordModel.newPassword" class="form-input">
              <template #prefix>
                <font-awesome-icon icon="fa-solid fa-key" style="font-weight: 900; font-size: 25px; color: #090970"/>
              </template>
            </lay-input>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="verificationPassed">
            <label class="form-label">Confirm New Password</label>
            <lay-input type="password" v-model="forgotPasswordModel.confirmNewPassword" class="form-input">
              <template #prefix>
                <font-awesome-icon icon="fa-solid fa-key" style="font-weight: 900; font-size: 25px; color: #090970"/>
              </template>
            </lay-input>
          </lay-form-item>
          <lay-form-item class="form-item" v-if="verificationPassed">
            <lay-button class="form-btn" @click="resetPassword">Reset Password</lay-button>
          </lay-form-item>
        </lay-form>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { reactive, ref } from "vue";
import { layer } from "@layui/layui-vue";
 import { post } from "@/api";
import { useRouter } from "vue-router";

export default {
  name: "ForgotPassword",
  components: {
    FontAwesomeIcon
  },
  setup() {
    const forgotPasswordForm = ref();
    const forgotPasswordModel = reactive({
      email: "",
      verificationCode: "",
      newPassword: "",
      confirmNewPassword: ""
    });
    const router = useRouter();
    const showVerification = ref(false);
    const verificationPassed = ref(false);
    const countdown = ref(0);

    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the email"));
      } else {
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+/;
        if (regEmail.test(value)) {
          return true;
        } else {
          callback(new Error("Please input an email with legal format"));
        }
      }
    };

    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the new password"));
      } else if (value.length < 6) {
        callback(new Error("Password must be at least 6 characters"));
      } else {
        return true;
      }
    };

    const validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please confirm the new password"));
      } else if (value !== forgotPasswordModel.newPassword) {
        callback(new Error("Passwords do not match"));
      } else {
        return true;
      }
    };

    const rules = reactive({
      email: { validator: validateEmail, trigger: "blur" },
      newPassword: { validator: validatePassword, trigger: "blur" },
      confirmNewPassword: { validator: validateConfirmPassword, trigger: "blur" }
    });

    const sendCode = () => {
      forgotPasswordForm.value.validate((isValid) => {
        if (!isValid) return;
        post(`/email/send-email`, { email: forgotPasswordModel.email }).then((res) => {
          console.log("Response from send-email API:", res);
          if (res.res === 1) {
            layer.msg("Verification code sent!", { icon: 1, time: 1500 });
            showVerification.value = true;
            startCountdown();
          } 
        })
      });
    };

    const startCountdown = () => {
      countdown.value = 60;
      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    };

    const verifyCode = () => {
      forgotPasswordForm.value.validate((isValid) => {
        if (!isValid) return;
         post(`/email/check-captcha`, {
           email: forgotPasswordModel.email,
           captcha: forgotPasswordModel.verificationCode
         }).then((res) => {
           if (res.res === true) {
            layer.msg("Verification code verified!", { icon: 1, time: 1500 });
            verificationPassed.value = true;
           } else {
             layer.msg("Invalid verification code, try again...", { icon: 2, time: 1500 });
           }
         });
      });
    };

    const resetPassword = () => {
      forgotPasswordForm.value.validate((isValid) => {
        if (!isValid || !verificationPassed.value) return;
        post(`/user/find-pwd`, {
          email: forgotPasswordModel.email,
          pwd: forgotPasswordModel.newPassword
        }).then((res) => {
          console.log("Response from find-pwd API:", res);
          if (res.res === 1) {
            layer.msg("Password reset successfully!", { icon: 1, time: 1500 });
            router.push("/login");
          } else {
            layer.msg("Failed to reset password, try again...", { icon: 2, time: 1500 });
          }
        }).catch((error) => {
          console.error("Error in find-pwd API call:", error);
          layer.msg("Failed to reset password, try again...", { icon: 2, time: 1500 });
        });
      });
    };

    return {
      forgotPasswordForm,
      forgotPasswordModel,
      rules,
      showVerification,
      verificationPassed,
      countdown,
      sendCode,
      verifyCode,
      resetPassword
    };
  }
};
</script>

<style scoped>
.forgot-password-view {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
}

.forgot-password-wrap {
  width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.forgot-password-box {
  display: block;
}

.form-title {
  font-size: 24px;
  font-family: "Poppins-Black";
  color: #5473ff;
  margin-bottom: 30px;
  text-align: center;
}

.form-item {
  margin-top: 20px;
}

.form-label {
  display: block;
  font-size: 18px;
  font-family: "Poppins-Bold";
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding: 0 16px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 15px;
}

.form-btn {
  display: block;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #fff !important;
  line-height: 50px;
  text-align: center;
  border: none;
  margin: 20px 0;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.form-btn:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
</style>
